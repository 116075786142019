import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import Layout from "../../components/layout"
import Slider from "../../components/slider"

const ProjectContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 485px 1fr;
  grid-template-rows: min-content min-content 1fr min-content;

  @media only screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr min-content;
  }
`

const ProjectGallery = styled.div`
  grid-row: 1/5;
  grid-column: 2/3;
  max-width: 100%;
  background: #000;
  height: 100vh;
  border-left: 1px solid #000000;

  @media only screen and (max-width: 760px) {
    grid-row: 4/5;
    grid-column: 1/1;
    height: 70vh;
    border-left: none;
  }
`

const Logo = styled(Link)`
  grid-row: 1/2;
  grid-column: 1/2;
  font-family: "AT Osmose", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 86px;
  text-transform: uppercase;
  margin-bottom: 22px;
  color: #000000;
  text-decoration: none;
  overflow: hidden;
  @media only screen and (max-width: 760px) {
    font-size: 62px;
  }
`

const ProjectInfo = styled.div`
  grid-row: 2/3;
  grid-column: 1/2;
  padding-left: 17px;
  padding-top: 22px;
  padding-bottom: 23px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: minmax(212px, 1fr) 1fr;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;

  font-family: "Work Sans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;

  @media only screen and (max-width: 760px) {
    font-size: 10px;
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
`

const Close = styled(Link)`
  position: absolute;
  right: 18px;
  top: 18px;
  width: 18px;
  height: 18px;
  opacity: 1;

  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: " ";
    height: 18px;
    width: 2px;
    background-color: #000000;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  transition: all.2s ease-in-out;

  &:hover {
    &:after {
      background-color: blue;
    }
    &:before {
      background-color: blue;
    }
  }
`

const MainText = styled.div`
  grid-row: 3/4;
  grid-column: 1/2;
  position: relative;
  margin: 0;
  padding-top: 22px;
  padding-left: 17px;
  padding-right: 76px;
  & p,
  & pre {
    max-width: 607px;
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
  }

  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  @media only screen and (max-width: 760px) {
    padding-bottom: 18px;
    font-size: 12px;
    & p,
    & pre {
      font-family: "Work Sans", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      max-width: 365px;
    }
  }
`

const ProjectsNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #000;
  margin-top: auto;
  padding: 15px 17px;
  height: 18px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;

  @media only screen and (max-width: 760px) {
    grid-row: 5/6;
    grid-column: 1/1;
    padding: 16px 18px;
  }
`

const NavButton = styled(Link)`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? ".6" : "1")};
  text-decoration: none;
  color: #000000;
  outline: none;
  border: none;

  &:hover {
    color: #0000ff;
    color: ${props => (props.disabled ? "#000000" : "#0000ff")};
  }
`

export const query = graphql`
  query($slug: String) {
    projectsJson(slug: { eq: $slug }) {
      name
      client
      scope
      description
      location
      slug
      type
      invertedThumb
      images {
        inverted
        url {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allProjectsJson {
      edges {
        node {
          slug
        }
      }
    }
  }
`

const withGraphql = ({ data }) => {
  const project = data.projectsJson
  const slugs = data.allProjectsJson.edges
  const index = slugs.findIndex(s => s.node.slug === project.slug)

  const prevPageSlug =
    index === 0 ? null : `/project/${slugs[index - 1].node.slug}`
  const nextPageSlug =
    index === slugs.length - 1 ? null : `/project/${slugs[index + 1].node.slug}`

  return (
    <Layout>
      <ProjectContainer>
        <Logo to="/">shoplift</Logo>
        <motion.div
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <ProjectInfo>
            <div>
              PROJECT: {project.name}
              <br />
              SCOPE OF WORK: {project.scope}
              <br />
            </div>
            <div>
              {project.client !== "" && (
                <>
                  CLIENT:{project.client}
                  <br />
                </>
              )}
              YEAR: 2020 <br />
              LOCATION: {project.location}
              <br />
            </div>
          </ProjectInfo>
        </motion.div>
        <MainText>
          <Close to="/" />
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {project.slug !== "engelr-hashim" ? (
              <p>{project.description}</p>
            ) : (
              <pre>{project.description}</pre>
            )}
          </motion.div>
        </MainText>
        <ProjectsNavigation>
          <NavButton
            to={prevPageSlug ? prevPageSlug : "/"}
            disabled={prevPageSlug ? false : true}
          >
            PREVIOUS PROJECT
          </NavButton>
          <NavButton
            to={nextPageSlug ? nextPageSlug : "/"}
            disabled={nextPageSlug ? false : true}
          >
            NEXT PROJECT
          </NavButton>
        </ProjectsNavigation>
        <ProjectGallery>
          <Slider images={project.images} />
        </ProjectGallery>
      </ProjectContainer>
    </Layout>
  )
}

export default withGraphql
